import React, {useEffect} from "react";
import {Props} from "./interface";
import styles from "./TextField.module.css"
import {TextField as TextFieldMUI} from "@mui/material";
import {getFieldType, getInputType, getSizes, handleNumberCommaFormatter, handleTextFieldInput, numberCommaFormatter, toEnDigit} from "../../../../helpers/functions";
import ValidationError from "../../../UI/ValidationError/ValidationError";
import useDataPayload, {RefDataType} from "../../../../hooks/use-data-payload/use-data-payload";


const TextField = React.forwardRef<RefDataType<string>, Props>((props, ref) => {


    /*---------------------------------------------------------------
   *                            HOOKS
   * --------------------------------------------------------------*/

    /************************************
     *   SET & GET VALIDATION AND STATES
     * *********************************/
    const {state, validation} = useDataPayload<string>({
        ref,
        config: props.config,
        autoSaveDelay: 3000,
        setConfig: props.setConfig,
        defaultValue:""
    })
    const [displayedValue, setDisplayedValue] = React.useState<string>('')


    useEffect(() => {
        if(props.config?.thousands_separator){
            setDisplayedValue(numberCommaFormatter(state?.value))
        } else {
            setDisplayedValue(state?.value || '')
        }
    }, [props.config?.thousands_separator])
    /*---------------------------------------------------------------*/


    /************************************
     *          CONSTANTS
     * *********************************/
    const type = getFieldType(props.config.format.toLowerCase())
    const inputType = getInputType(props.config.format.toLowerCase())


    /************************************
     *         ACTION HANDLER
     * *********************************/

    const handleInput = (e) => {
        const stateSetter = (value) => {
            state.set(value)
        }

        handleTextFieldInput({
             event: e,
             fieldFormat: props.config.format,
             setDisplayedValue,
             stateSetter,
             thousandsSeparator: props.config.thousands_separator
            })
    };
    

    useEffect(() => {
        validation.clear()
    }, [state.value])

    /************************************
     *  PREVENT SCROLLING ON INPUTS
     * *********************************/

    document.addEventListener("wheel", function (event) {
        if (type === "number")
            document.getElementById("outlined-text")?.blur()

    });

    /************************************
     *       SET ATTRIBUTES
     * *********************************/
    const attributes = {
        id: props.config.identifier,
        fullWidth: true,
        maxRows: 4,
        placeholder: props.config.placeholder.locale,
        InputLabelProps: {
            style: {
                color: props.config.placeholder.color,
                fontWeight: props.config.placeholder.style?.bold ? 'bold' : 'normal',
                fontSize: getSizes(props.config.placeholder.style?.size),
            }
        },
        InputProps: {
            style: {
                color: props.config.text.color,
                fontWeight: props.config.text?.style?.bold ? 'bold' : 'normal',
                fontSize: window.innerWidth <= 768 ? '18px' : getSizes(props.config.text?.style?.size),
                backgroundColor:"#fff"
                // padding: "15px"
            },
            // disableUnderline: true,
            readOnly: !props.config.editable,


        }
    }

    return (
        <>
            <div  className={`${styles.container}`} style={{display: "flex"}}>
                    <TextFieldMUI
                        sx={{'.MuiOutlinedInput-notchedOutline': {borderRadius:"8px", borderColor: "#E6E3E3" }}}
                        autoComplete={"off"}
                        {...attributes} value={displayedValue}
                        onChange={handleInput}
                        variant={'outlined'}
                        // variant={'standard'}
                        inputProps={{maxLength: 100}}
                        inputMode={inputType}
                        autoCorrect={"off"}
                        spellCheck={"false"}
                        onPaste={(event) => {
                            if (type != 'text') {
                                event.preventDefault()
                            }
                        }}
                        onCopy={(event) => {
                            if (type != 'text') {
                                event.preventDefault()
                            }
                        }} onCut={(event) => {

                        if (type != 'text') {
                            event.preventDefault()
                        }
                    }}
                        onKeyPress={handleInput}
                    />

            </div>
            <ValidationError validation={validation}/>
        </>

    )
})

export default TextField