import React, {useContext, useEffect, useImperativeHandle, useState} from "react";
import styles from "./TextField.module.css";
import {Props, RefDataType} from "./interface";
import PostContext from "../../../../storage/PostContext";
import languageContext from "../../../../storage/LanguageContext";
import {getFieldType, getInputType, getSizes, handleNumberCommaFormatter, handleTextFieldInput, numberCommaFormatter, toEnDigit} from "../../../../helpers/functions";
import {FormHelperText, TextField as TextFieldMUI} from "@mui/material";
import useValidationNew from "../../../../hooks/use-validation/use-validation-new";

const TextField = React.forwardRef<RefDataType, Props>((props, ref) => {

    /************************************
     *              CONTEXT
     * *********************************/
const postCtx = useContext(PostContext)
    const langCtx = useContext(languageContext)

    /************************************
     *              STATES
     * *********************************/
    const [value, setValue] = useState<string>(postCtx.data.form[props.config.identifier] ?? '')
    const [displayedValue, setDisplayedValue] = React.useState<string>('')

    useEffect(() => {
        if(props.config?.thousands_separator){
            setDisplayedValue(numberCommaFormatter(postCtx.data.form[props.config.identifier]))
        } else {
            setDisplayedValue(postCtx.data.form[props.config.identifier]|| '')
        }
    }, [props.config?.thousands_separator])

    /*---------------------------------------------------------------
     *                            HOOKS
     * --------------------------------------------------------------*/

    /************************************
     *     GET AND CLEAR VALIDATION
     * *********************************/
    const {error, setValidationError, clearValidationError} = useValidationNew()

    /*---------------------------------------------------------------*/

    /************************************
     *            CONSTANTS
     * *********************************/
    const inputType = getInputType(props.config.format.toLowerCase() )
    const alignCenter = (langCtx.language == 'en') ? 'left' : 'right'
    const type = getFieldType(props.config.format.toLowerCase() )

    /************************************
     *        ACTION HANDLER
     * *********************************/

    const handleInput = (e) => {

        clearValidationError()
        const stateSetter = (value) => {
            setValue(value)
        }
        handleTextFieldInput({
             event: e,
             fieldFormat: props.config.format,
             setDisplayedValue,
             stateSetter,
             thousandsSeparator: props.config.thousands_separator
            })
    };

    /************************************
     *       PREVENT SCROLLING
     * *********************************/
    document.addEventListener("wheel", function (event) {
        if (type === "number")
            document.getElementById("outlined-text")?.blur()

    });
    /************************************
     *   GET INNER STATE USING REFS
     * *********************************/
    useImperativeHandle<RefDataType, RefDataType>(ref, () => {
        return {
            getState: (): string => value,
            setValidationError,
            scrollIntoView: () => {
            }
        }
    })
    /************************************
     *       SET ATTRIBUTES
     * *********************************/
    const attributes = {
        id: "outlined-text",
        fullWidth: true,
        maxRows: 4,
        placeholder: props.config.placeholder.locale,
        InputLabelProps: {
            style: {
                color: props.config.placeholder.color,
                fontWeight: props.config.placeholder.style?.bold ? 'bold' : 'normal',
                fontSize: getSizes(props.config.placeholder.style?.size),
            }
        },
        InputProps: {
            style: {
                color: props.config.text.color,
                fontWeight: props.config.text?.style?.bold ? 'bold' : 'normal',
                fontSize: window.innerWidth <= 768 ? '18px' : getSizes(props.config.text?.style?.size),
            },
            readOnly: !props.config.editable,

        }
    }

    return (
        <>
            <div className={`mt-2  ${styles.container}`} style={{display: "flex"}}>

                    <TextFieldMUI {...attributes}
                                  value={displayedValue} variant={'outlined'}
                                  inputProps={{maxLength: 100}}
                                  inputMode={inputType}
                                  autoComplete={"off"}
                                  autoCorrect={"off"}
                                  spellCheck={"false"}
                                  onChange={handleInput}
                                  onPaste={(event) => {
                                      if (type != 'text') {
                                          event.preventDefault()
                                      }
                                  }}
                                  onCopy={(event) => {
                                      if (type != 'text') {
                                          event.preventDefault()
                                      }
                                  }}
                                  onCut={(event) => {

                                      if (type != 'text') {
                                          event.preventDefault()
                                      }
                                  }}
                                  onKeyPress={handleInput}

                    />
            </div>
            {error && <FormHelperText style={{
                color: "red",
                fontSize: "15px",
                paddingTop: "2px",
                textAlign: alignCenter

            }}>
                {`*  ${error}`}
            </FormHelperText>}
        </>

    )
})

export default TextField